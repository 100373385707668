.wd-banner-counter {
  padding: 80px 0px 105px;
  background-image: url(../../assets/images/partners/couter.png);
}
.wd-banner-CTA {
  background-image: url(../../assets/images/partners/CTA.png);
  image-rendering: pixelated;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0px;
  background-repeat: no-repeat;
}
