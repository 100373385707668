.offerStrip {
    background-color: #ffcc00;
    color: #000;
    text-align: center;
    padding: 10px 0;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .offerStrip {
      display: none;
    }
  }