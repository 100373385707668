.table-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 10px;
  }
  
  .fake-table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .fake-table thead {
    background-color: #123841;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
  }
  
  .fake-table th, .fake-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .fake-table tbody tr:hover {
    background-color: #f9f9f9;
  }